import React from "react";
import { NextLink } from "@src/components";
import Stack from "@mui/material/Stack";
import { getClassNameActive } from "../config";
import MenuWithSubItem from "./menu-with-sub-item";
const Menu = ({
  menuConfigList,
  currentPath,
  onRedirect
}) => {
  return <Stack alignItems="center" justifyContent="space-between" display={{
    xs: "none",
    md: "flex"
  }} sx={{
    width: "100%",
    maxWidth: 1250,
    margin: "0px auto",
    "& .MuiStack-root": {
      display: "flex",
      alignItems: "center",
      width: 176,
      height: 48,
      fontWeight: 400,
      lineHeight: "24px",
      justifyContent: "center",
      "& a": {
        color: "#808080",
        fontSize: 16,
        "&:hover": {
          fontWeight: 700,
          color: "#000"
        },
        "&.active": {
          fontWeight: 700,
          color: "#000"
        }
      }
    }
  }} data-sentry-element="Stack" data-sentry-component="Menu" data-sentry-source-file="index.tsx">
      {menuConfigList.map((item, index) => item?.subMenu ? <MenuWithSubItem key={index} data={item} currentPath={currentPath} onRedirect={onRedirect} /> : <NextLink key={index} className={getClassNameActive(item, currentPath)} href={item.path} style={{
      color: item.color
    }}>
            {item.title}
          </NextLink>)}
    </Stack>;
};
export default Menu;
import { ROUTES } from "@src/constants/routes"

export const getClassNameActive = (data, currentPath) => {
  if (!currentPath) {
    return ""
  }

  if (data?.subMenu) {
    const subMenu = data?.subMenu?.find(item =>
      currentPath.startsWith(item.path)
    )
    if (subMenu) {
      return "active"
    }
  }

  if (
    data?.isHome &&
    ["/", "/#", ROUTES.BEST_SELLER, ROUTES.NEW_IN].includes(
      currentPath
    )
  ) {
    return "active"
  }

  if (!data?.isHome && currentPath.includes(data.path)) {
    return "active"
  }
  return ""
}

export const menuConfig = [
  {
    title: "TRANG CHỦ",
    isHome: true,
    path: ROUTES.HOME_PAGE,
    isActive: true
  },
  {
    title: "COLLECTIONS",
    path: ROUTES.COLLECTION,
    isActive: true
  },
  {
    title: "SẢN PHẨM",
    path: ROUTES.PRODUCT,
    isActive: true
  },
  {
    title: "ON SALE",
    path: ROUTES.ON_SALE,
    color: '#FF5F67',
    isActive: false
  },
  {
    title: "EVENT",
    path: ROUTES.EVENT,
    isActive: true
  },
  {
    title: "GIỚI THIỆU",
    path: ROUTES.INTRODUCE,
    isActive: true,
    subMenu: [
      {
        title: "ABOUT US",
        path: ROUTES.ABOUT_US,
        isActive: true
      },
      {
        title: "MEMBERSHIP",
        path: ROUTES.MEMBERSHIP,
        isActive: true
      },
      {
        title: "CHI NHÁNH",
        path: ROUTES.BRANCH,
        isActive: true
      },
      {
        title: "TUYỂN DỤNG",
        path: ROUTES.RECRUITMENT,
        isActive: true
      },
      {
        title: "FAQ",
        path: ROUTES.FAQ,
        isActive: true
      }
    ]
  },
  {
    title: "BLOG",
    path: ROUTES.BLOG,
    isActive: true
  }
]

import React from "react";
import { Icons } from "@src/components";
import Badge from "@mui/material/Badge";
const BadgeIcon = ({
  iconName,
  badgeNumber,
  onClick
}) => {
  if (badgeNumber === 0) {
    return <Icons name={iconName} type="button" onClick={onClick} />;
  }
  const badgeContent = badgeNumber > 99 ? "99+" : badgeNumber;
  return <Badge badgeContent={badgeContent} color="success" sx={{
    "& .MuiBadge-badge": {
      right: {
        xs: 6,
        md: 12
      },
      top: {
        xs: 9,
        md: 18
      },
      fontSize: badgeNumber > 99 ? 8 : 11,
      fontWeight: 600,
      width: 20,
      height: 20,
      borderRadius: "50%",
      backgroundColor: "red",
      padding: 0
    }
  }} data-sentry-element="Badge" data-sentry-component="BadgeIcon" data-sentry-source-file="badge-icon.tsx">
      <Icons name={iconName} type="button" onClick={onClick} data-sentry-element="Icons" data-sentry-source-file="badge-icon.tsx" />
    </Badge>;
};
export default BadgeIcon;